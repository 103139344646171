import dataProject from '../../Services/data/dataUser.json';
import {IExperience, ITag} from '../../types/interfaces';

const FetchDataProjectAll = () => {
  return (
    <>
      {dataProject[0].experiences.map(function (element: IExperience) {
        const array: ITag[] = element.tag;

        let result = (
          <div
            className='column is-4'
            key={element.Key}
          >
            <a href={element.link}>
              <div className='card'>
                <div className='card-content'>
                  <div className='media'>
                    <div className='media-content'>
                      <h2 className='title is-4 googleFontsConcertOne'>
                        {element.CompanyName}
                      </h2>
                      <h2 className='subtitle is-6 googleFontsMontserrat fontSizeSubtitle'>
                        {element.JobTtile}
                      </h2>
                    </div>
                  </div>

                  <div className='content textProject googleFontsPoppins fontSizeParagraphe'>
                    <p>{element.text}</p>
                  </div>
                  <div className='tags googleFontsPoppins'>
                    {array.map(function (tag) {
                      return (
                        <span
                          key={tag.Key}
                          className='tag is-dark fontSizeTag'
                        >
                          {tag.name}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
        return result;
      })}
    </>
  );
};

export default FetchDataProjectAll;
