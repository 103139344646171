import React from 'react';

import './pageRutabago.css';

const Rutabago = () => {
  const linkRutabago = () => {
    window.open('https://rutabago.com/', '_blank');
  };

  return (
    <section className="section is-medium paddingSection">
      <div className="container content">
        <h2 className="fontSizeTitle googleFontsConcertOne has-text-centered">
          Rutabago
        </h2>

        <p className="fontSizeSubtitle googleFontsMontserrat">
          Rutabago is a delivery company for "organic" meals, to be cooked at
          home. On Paris.
        </p>

        <p className="fontSizeSubtitle googleFontsMontserrat pb-3">
          My mission within Rutabago was to create a back office. The redesign
          of the site for version 2. As well as improving the Php architecture
          of Rutabago.
        </p>

        <ul>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Back-office: creation of a back-office in order to have a
              visualization of the statistics live or according to a very
              precise date.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Redesign of the site: update of the design of the site with a new
              graphic charter.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Bulma: integration of the Bulma CSS framework.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Font-awesome: Updated Font-awesome 3 to 4.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Cypress.io: IMH test creation (GUI)
            </p>
          </li>
        </ul>

        <div className="myTechno pt-2">
          <h4 className="fontSizeSubtitle googleFontsMontserrat">
            Technologies used
          </h4>
          <div className="tags googleFontsPoppins">
            <span className="tag is-dark fontSizeTag">Php</span>
            <span className="tag is-dark fontSizeTag">Prestashop</span>
            <span className="tag is-dark fontSizeTag">Javascript</span>
            <span className="tag is-dark fontSizeTag">CSS</span>
            <span className="tag is-dark fontSizeTag">Bulma</span>
            <span className="tag is-dark fontSizeTag">Cypress.io</span>
            <span className="tag is-dark fontSizeTag">Font-awesome</span>
            <span className="tag is-dark fontSizeTag">IMH test</span>
          </div>
        </div>

        <div className="most">
          <h4 className="fontSizeSubtitle googleFontsMontserrat pb-2">
            What the project brought me
          </h4>
          <blockquote className="fontSizeParagraphe googleFontsPoppins borderLeft">
            Thanks to Rutabago, I understood that I wanted more stimulating
            experiences that put me in difficulty. In order to always have an
            evolution within my development.
          </blockquote>
        </div>

        <div className="buttons">
          <button
            onClick={linkRutabago}
            className="button is-dark is-medium buttonRedirect"
          >
            <p className="fontSizeParagraphe googleFontsMontserrat">
              Rutabago.com
            </p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Rutabago;
