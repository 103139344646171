import React from 'react';

import dataTechnologys from '../../Services/data/dataUser.json';

import javascript from '../../Assets/images/svg/square-js-brands.svg';
import nodeJs from '../../Assets/images/svg/node-js-brands.svg';
import react from '../../Assets/images/svg/react-brands.svg';
import aws from '../../Assets/images/svg/aws-brands.svg';
import python from '../../Assets/images/svg/python-brands.svg';

import './technology.css';
import {IData, ITechnologys} from '../../types/interfaces';

const FetchDataTechnology = () => {
  return (
    <>
      {dataTechnologys.map((array: IData) => {
        let dataArray = array.technologys.map(function (element: ITechnologys) {
          return (
            <div
              className='level-item has-text-centered'
              key={element.key}
            >
              <div>
                <h2 className='heading googleFontsMontserrat fontSizeTechnologys'>
                  {element.nameTechnology}
                </h2>
                <button className='title button is-dark is-medium'>
                  <span className='icon is-medium'>
                    {element.icone === 'Javascript' ? (
                      <img
                        className='iconeTechnology'
                        src={javascript}
                        alt='icone Javascript'
                      />
                    ) : element.icone === 'node-js' ? (
                      <img
                        className='iconeTechnology'
                        src={nodeJs}
                        alt='icone Node.js'
                      />
                    ) : element.icone === 'react' ? (
                      <img
                        className='iconeTechnology'
                        src={react}
                        alt='icone React.js'
                      />
                    ) : element.icone === 'aws' ? (
                      <img
                        className='iconeTechnology'
                        src={aws}
                        alt='icone Amazon Web Service'
                      />
                    ) : element.icone === 'python' ? (
                      <img
                        className='iconeTechnology'
                        src={python}
                        alt='icone python'
                      />
                    ) : null}
                  </span>
                </button>
              </div>
            </div>
          );
        });
        return dataArray;
      })}
    </>
  );
};

export default FetchDataTechnology;
