import React from 'react';

import 'bulma-timeline/dist/css/bulma-timeline.min.css';

import FetchDataPro from './FetchDataPro';
import Fetchdataschool from './Fetchdataschool';

import './timeline.css';

const Timeline = () => {
  return (
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className="timeline is-centered">
            <header className="timeline-header">
              <h2 className="tag is-medium is-dark googleFontsConcertOne">
                Experience
              </h2>
            </header>

            <FetchDataPro />

            <header className="timeline-header">
              <span className="tag is-medium is-dark">-</span>
            </header>
          </div>
        </div>
        <div className="column">
          <div className="timeline is-centered">
            <header className="timeline-header">
              <h2 className="tag is-medium is-dark googleFontsConcertOne">
                Formation
              </h2>
            </header>

            <Fetchdataschool />

            <header className="timeline-header">
              <span className="tag is-medium is-dark">-</span>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
