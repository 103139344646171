import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons';

import './scrollButton.css';

const ScrollButton = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisible = () => {
    const scrolled: number = document.documentElement.scrollTop;
    if (scrolled > 250) {
      setVisible(true);
    } else if (scrolled <= 250) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div id='ScrollToTop'>
      <button
        className='button is-medium ScrollToTop is-dark'
        style={{display: visible ? 'inline' : 'none'}}
        onClick={scrollToTop}
      >
        <span className='icon is-medium'>
          <FontAwesomeIcon
            icon={faArrowUp}
            color='white'
          />
        </span>
      </button>
    </div>
  );
};

export default ScrollButton;
