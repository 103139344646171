import React from 'react';

import './pageEsens.css';

const Esens = () => {
  const linkAedile = () => {
    window.open('https://eemeo-taaaa-aaaad-qakjq-cai.ic.fleek.co/', '_blank');
  };
  const linkEsens = () => {
    window.open('https://www.esensconsulting.com/', '_blank');
  };

  return (
    <section className="section is-medium paddingSection">
      <div className="container content">
        <h2 className="fontSizeTitle googleFontsConcertOne has-text-centered">
          Esens
        </h2>
        <p className="fontSizeSubtitle googleFontsMontserrat">
          'Esens Consulting' is an expert company in IT consulting. With a
          concern to respect and value these employees. Esens encourages
          personal development, and the expertise of it's employing.
        </p>
        <p className="fontSizeSubtitle googleFontsMontserrat pb-3">
          I was hired as a JavaScript consultant (Node.js, React.js & AWS). In
          order to bring my expertise on the IT development of a partner company
          of Esens.
        </p>
        <p className="fontSizeSubtitle googleFontsMontserrat pb-3">
          Add within the Esens R&D Lab. I was able to work on a new technology
          which is the internet computer (web 3.0). Created by the company
          Dfinity, it stands out thanks to its technology based on the 'ICP'
          blockchain, which allows complete decentralization of the application
          on the Internet. The application of this new technology was the
          creation of the 'Aedile' project.
        </p>

        <h3 className="fontSizeSubtitle googleFontsMontserrat">Aedile</h3>

        <p className="fontSizeSubtitle googleFontsMontserrat pb-3">
          Aedile is the first open project management service hosted on the
          internet computer Dfinity. The idea is to offer teams and individuals
          a decentralized alternative to their favorite tools in order to bring
          agility to cyberspace! Aedile is 'Trello meets Jira with a DAO twist'.
          Add to the project, as Front-end JavaScript developer (Vue.js).
        </p>

        <ul>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Authentication: use of “Internet Identity” services and “Stoic
              Wallet” for login/register management.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Profile: Creation of a customizable profile in Markdown by the
              user and the edition of the user profile.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Comment: added 'comment's functionality, added/delete/display
              comments
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Invitation: Management of invitations for a board or a team
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Dark mode: add a dark mode in the project.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Communication: reflection/creation of posts for the networks
              socials of 'Aedile'
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              FAQ: creation of user FAQs
            </p>
          </li>
        </ul>

        <div className="myTechno pt-2">
          <h4 className="fontSizeSubtitle googleFontsMontserrat">
            Technologies used
          </h4>
          <div className="tags googleFontsPoppins">
            <span className="tag is-dark fontSizeTag">Vue.js</span>
            <span className="tag is-dark fontSizeTag">Vuetify</span>
            <span className="tag is-dark fontSizeTag">TypeScript</span>
            <span className="tag is-dark fontSizeTag">Javascript</span>
            <span className="tag is-dark fontSizeTag">DFinity</span>
            <span className="tag is-dark fontSizeTag">Internet Computer</span>
            <span className="tag is-dark fontSizeTag">BlockChain</span>
            <span className="tag is-dark fontSizeTag">ICP</span>
            <span className="tag is-dark fontSizeTag">CSS</span>
            <span className="tag is-dark fontSizeTag">Markdown</span>
          </div>
        </div>

        <div className="most">
          <h4 className="fontSizeSubtitle googleFontsMontserrat pb-2">
            What the project brought me
          </h4>
          <blockquote className="fontSizeParagraphe googleFontsPoppins borderLeft">
            Thanks to Esens, I was able to learn and apply new technologies, but
            also to prove my capacities as a JavaScript developer acquired by my
            experiences and my training in the past.
          </blockquote>
          <blockquote className="fontSizeParagraphe googleFontsPoppins borderLeft">
            And thus confirmed my basic knowledge of certain programming
            languages such as: Vue.js and JavaScript. But also go deeper in
            understanding and getting started with some like TypeScript.
          </blockquote>
        </div>

        <div className="buttons">
          <button
            onClick={linkAedile}
            className="button is-dark is-medium buttonRedirect"
          >
            <p className="fontSizeParagraphe googleFontsMontserrat">Aedile</p>
          </button>
        </div>
        <div className="buttons">
          <button
            onClick={linkEsens}
            className="button is-dark is-medium buttonRedirect"
          >
            <p className="fontSizeParagraphe googleFontsMontserrat">
              Esens consulting
            </p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Esens;
