import React from 'react';

import Linkedin from '../../Assets/images/svg/linkedin-brands.svg';

import './contact.css';

const Contact = () => {
  const linkLinkedin = () => {
    window.open('https://www.linkedin.com/in/etienne-juzans/', '_blank');
  };

  const linkGitHub = () => {
    window.open('https://github.com/JKS9', '_blank');
  };

  const linktwitter = () => {
    window.open('https://twitter.com/JKS_0_0', '_blank');
  };

  const linkInstagram = () => {
    window.open('https://www.instagram.com/etiennejuz/', '_blank');
  };

  return (
    <div className="container">
      <h2 className="title is-3 titleSection googleFontsConcertOne">
        Information about ?
      </h2>
      <p className="subtitle is-6 googleFontsPoppins fontSizeParagraphe">
        Feel free to contact me for more <strong>information.</strong> You can
        contact me by <strong>email</strong> or through the various{' '}
        <strong>social networks</strong> below.
      </p>
      <h2 className="has-text-centered pb-4 googleFontsMontserrat fontSizeSubtitle">
        <strong>juzans@etiennejuz.com</strong>
      </h2>
      <div className="divider has-background-light" data-content="OR"></div>
      <div className="socialNetwork pt-4">
        <div className="columns has-text-centered is-mobile">
          <div className="column is-3">
            <button className="button is-dark is-medium" onClick={linkLinkedin}>
              <span className="icon is-medium">
                <img
                  src={Linkedin}
                  className="iconeNetwork"
                  alt="icone Linkedin"
                />
              </span>
            </button>
          </div>
          <div className="column is-3">
            <button className="button is-dark is-medium" onClick={linkGitHub}>
              <span className="icon is-medium">
                <i className="fab fa-github"></i>
              </span>
            </button>
          </div>
          <div className="column is-3">
            <button className="button is-dark is-medium" onClick={linktwitter}>
              <span className="icon is-medium">
                <i className="fab fa-twitter iconeNetwork"></i>
              </span>
            </button>
          </div>
          <div className="column is-3">
            <button
              className="button is-dark is-medium"
              onClick={linkInstagram}
            >
              <span className="icon is-medium">
                <i className="fab fa-instagram"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
