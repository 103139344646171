import React from 'react';

import './technology.css';
import FetchDataTechnology from './fetchDataTechnology';

export default function Technology() {
  return (
    <div className="container">
      <nav className="level is-desktop">
        <FetchDataTechnology />
      </nav>
    </div>
  );
}
