import './project.css';

import FetchDataProjectAll from './fetchDataProjectAll';

const Project = () => {
  return (
    <div className='container'>
      <p className='subtitle is-5 googleFontsPoppins fontSizeSubtitle'>
        Here are some experiences that have been very meaningful to me during my
        journey as a Back-end JavaScript developer
      </p>
      <div className='columns is-multiline'>
        <FetchDataProjectAll />
      </div>
    </div>
  );
};

export default Project;
