import React from 'react';

import FetchDataUser from './fetchDataUser';
import EtienneBG from '../../Assets/images/jpeg/EtienneJuzans.jpg';
import './aboutMe.css';

const AboutMe = () => {
  return (
    <div className="container">
      <div className="columns is-vcentered is-gapless">
        <div className="column is-two-fifths">
          <figure className="image is-480x600">
            <img
              className="pictureEtienne"
              src={EtienneBG}
              alt="Juzans Etienne Developper JS Paris"
            />
          </figure>
        </div>
        <FetchDataUser />
      </div>
    </div>
  );
};

export default AboutMe;
