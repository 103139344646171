import React from 'react';
import 'bulma-timeline/dist/css/bulma-timeline.min.css';

import dataPro from '../../Services/data/dataUser.json';
import {IExperience} from '../../types/interfaces';

const FetchDataPro = () => {
  return (
    <>
      {dataPro[0].experiences.map(function (element: IExperience) {
        return (
          <div
            className='timeline-item'
            key={element.Key}
          >
            <div className='timeline-marker is-dark'></div>
            <div className='timeline-content'>
              <h3 className='heading has-text-weight-bold googleFontsPoppins fontSizeParagraphe'>
                {element.CompanyName}
              </h3>
              <h4 className='is-size-7 has-text-weight-light googleFontsMontserrat'>
                {element.Date}
              </h4>
              <h3 className='googleFontsMontserrat fontSizeParagraphe'>
                {element.JobTtile}
              </h3>
              <a
                className='is-size-7 has-text-weight-light googleFontsPoppins'
                href={element.link}
              >
                <span>Know more</span>
              </a>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FetchDataPro;
