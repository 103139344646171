import React from 'react';

import EtienneBG from '../../Assets/images/jpeg/EtienneJuzans.jpg';
import Linkedin from '../../Assets/images/svg/linkedin-brands.svg';

import './pageLink.css';

const PageLink = () => {
  const linkLinkedin = () => {
    window.open('https://www.linkedin.com/in/etienne-juzans/', '_blank');
  };

  const linkGitHub = () => {
    window.open('https://github.com/JKS9', '_blank');
  };

  const linktwitter = () => {
    window.open('https://twitter.com/JKS_0_0', '_blank');
  };

  const linkInstagram = () => {
    window.open('https://www.instagram.com/etiennejuz/', '_blank');
  };

  const linkPortfolio = () => {
    window.open('https://www.etiennejuz.com', '_blank');
  };

  return (
    <section className='section is-medium paddingSection pb-6'>
      <div className='columns is-centered'>
        <div className='column is-half'>
          <div className='picture'>
            <img
              src={EtienneBG}
              className='image-circular'
              alt='Etienne Juzans'
            />
          </div>
          <div className='text'>
            <h1 className='is-is-fullwidth-3 has-text-centered is-dark has-text-weight-light googleFontsConcertOne'>
              Etienne Juzans
            </h1>
            <h2 className='is-is-fullwidth-4 has-text-centered mb-5 has-text-weight-light googleFontsMontserrat'>
              Back-end JavaScript developer
            </h2>
          </div>
          <div className='list-button'>
            <div className='centered'>
              <button
                className='button is-dark is-fullwidth mb-5'
                onClick={linkGitHub}
              >
                <span className='icon is-medium'>
                  <i className='fab fa-github'></i>
                </span>
              </button>
              <button
                className='button is-dark is-fullwidth mb-5'
                onClick={linkLinkedin}
              >
                <img
                  src={Linkedin}
                  className='iconeNetwork'
                  alt='icone Linkedin'
                />
              </button>
              <button
                className='button is-dark is-fullwidth mb-5'
                onClick={linktwitter}
              >
                <span className='icon is-medium'>
                  <i className='fab fa-twitter iconeNetwork'></i>
                </span>
              </button>
              <button
                className='button is-dark is-fullwidth mb-5'
                onClick={linkInstagram}
              >
                <span className='icon is-medium'>
                  <i className='fab fa-instagram'></i>
                </span>
              </button>
              <button
                className='button is-dark is-fullwidth mb-5'
                onClick={linkPortfolio}
              >
                <p className='is-is-fullwidth-6 has-text-centered has-text-weight-light textBio googleFontsMontserrat fontis-fullwidthParagraphe'>
                  Portfolio
                </p>
              </button>
              <button
                className='button is-fullwidth'
                style={{
                  borderColor: '#fff',
                  marginBottom: '1em',
                  backgroundColor: '#fff',
                }}
              >
                <a
                  className='buymecoffe'
                  href='https://www.buymeacoffee.com/jks'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src='https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png'
                    alt='Buy Me A Coffee'
                  />
                </a>
              </button>
            </div>
          </div>
          <div className='text-email'>
            <h3 className='is-is-fullwidth-5 has-text-centered has-text-weight-light googleFontsMontserrat'>
              juzans@etiennejuz.com
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageLink;
