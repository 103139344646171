import React from 'react';

import './pageSkeelApp.css';

const SkeeLApp = () => {
  const linkSkeelApp = () => {
    window.open('https://www.skeelapp.com/', '_blank');
  };

  return (
    <section className="section is-medium paddingSection">
      <div className="container content">
        <h2 className="fontSizeTitle googleFontsConcertOne has-text-centered">
          SkeeL App
        </h2>

        <p className="fontSizeSubtitle googleFontsMontserrat">
          Skeel is the first social network that lets you compare your Video
          Clips to other videos based on the same "topic/challenges" and earn
          rewards for your skills ! The community then votes to bring out the
          most talented users !
        </p>
        <p className="fontSizeSubtitle googleFontsMontserrat pb-3">
          I was added to the Skeel project to help IT teams. My mission was the
          creation of many views within the Mobile application and the
          optimization of the user journey.
        </p>

        <ul>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              User profile: creation of views linked to the user profile.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Parameter: creation of the "Parameter" view of Skeel App.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Search bar: formatting and creation of a search bar internally to
              the application, in order to search for a particular user or
              subject.
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Download view: allow a user to upload a video via their browser
            </p>
          </li>
          <li>
            <p className="fontSizeParagraphe googleFontsPoppins pb-1">
              Optimization: optimize the fluidity of the application for the
              user
            </p>
          </li>
        </ul>

        <div className="myTechno pt-2">
          <h4 className="fontSizeSubtitle googleFontsMontserrat">
            Technologies used
          </h4>
          <div className="tags googleFontsPoppins">
            <span className="tag is-dark fontSizeTag">React-Native</span>
            <span className="tag is-dark fontSizeTag">React-navigation</span>
            <span className="tag is-dark fontSizeTag">Javascript</span>
            <span className="tag is-dark fontSizeTag">CSS</span>
            <span className="tag is-dark fontSizeTag">Android</span>
            <span className="tag is-dark fontSizeTag">IOS</span>
          </div>
        </div>

        <div className="most">
          <h4 className="fontSizeSubtitle googleFontsMontserrat pb-2">
            What the project brought me
          </h4>
          <blockquote className="fontSizeParagraphe googleFontsPoppins borderLeft">
            Thanks to this project, I was able to understand the React-native
            ecosystem on android and IOS. A big upgrade I was asked to do to
            work on it.
          </blockquote>

          <blockquote className="fontSizeParagraphe googleFontsPoppins borderLeft">
            Following this upgrade, today I am able to build/think/improve a
            mobile application in "React-native".
          </blockquote>
        </div>

        <div className="buttons">
          <button
            onClick={linkSkeelApp}
            className="button is-dark is-medium buttonRedirect"
          >
            <p className="fontSizeParagraphe googleFontsMontserrat">
              SkeelApp.com
            </p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default SkeeLApp;
