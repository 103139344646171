import React, {useEffect, useState} from 'react';

import './footer.css';

export default function Footer() {
  const [dateWebSite, setDateWebSite] = useState<number>(2022);

  useEffect(() => {
    const todayDate: Date = new Date();
    const todayDateYear: number = todayDate.getFullYear();

    if (todayDateYear !== dateWebSite) {
      setDateWebSite(todayDateYear);
    }
  }, [dateWebSite]);

  return (
    <div id='Footer'>
      <footer className='footer googleFontsPoppins'>
        <div className='content has-text-centered'>
          <p className='textFooter'>
            Copyright &copy; {dateWebSite}
            <strong className='googleFontsConcertOne'> Etiennejuz</strong>. All
            Rights Reserved
          </p>
        </div>
      </footer>
    </div>
  );
}
