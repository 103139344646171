import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../Assets/images/svg/logo.svg';

import './header.css';

const Header = () => {
  const [isActive, setisActive] = useState<boolean>(false);

  const linkGitHub = () => {
    window.open('https://github.com/JKS9', '_blank');
  };

  return (
    <div id='Header'>
      <div className='container'>
        <nav
          className='navbar'
          role='navigation'
          aria-label='main navigation'
        >
          <div className='navbar-brand'>
            <Link
              className='navbar-item'
              to='/'
            >
              <img
                src={Logo}
                width='75'
                alt='EtienneJuz developper JS Logo'
              />
            </Link>

            <button
              onClick={() => {
                setisActive(!isActive);
              }}
              className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
              aria-label='menu'
              aria-expanded='false'
              data-target='navbarBasic'
            >
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
            </button>
          </div>

          <div
            id='navbarBasic'
            className={`navbar-menu ${isActive ? 'is-active' : ''}`}
          >
            <div className='navbar-end'>
              <a
                className='navbar-item googleFontsMontserrat'
                href='/#aboutMe'
              >
                About me
              </a>
              <a
                className='navbar-item googleFontsMontserrat'
                href='/#experience'
              >
                Experiences
              </a>
              <a
                className='navbar-item googleFontsMontserrat'
                href='/#project'
              >
                Project
              </a>
              <a
                className='navbar-item googleFontsMontserrat'
                href='/#contact'
              >
                Contact
              </a>
              <div className='navbar-item'>
                <div className='buttons'>
                  <button
                    onClick={linkGitHub}
                    className='button is-dark is-medium'
                    style={{width: isActive ? '100%' : ''}}
                  >
                    <span className='icon is-medium'>
                      <i className='fab fa-github'></i>
                    </span>
                  </button>
                </div>
              </div>
              <a
                className='buymecoffe'
                href='https://www.buymeacoffee.com/jks'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png'
                  alt='Buy Me A Coffee'
                />
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
