import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from './Home/Home';

import Header from '../Layouts/Header/header';
import Footer from '../Layouts/Footer/footer';
import NotFound from '../Layouts/NotFound/notFound';
import ScrollButton from '../Components/Button/ScrollButton/scrollButton';
import Cookie from '../Components/Cookie/cookie';

import SkeeLApp from './Project/SkeelApp/pageSkeelApp';
import Rutabago from './Project/Rutabago/pageRutabago';
import TastrUs from './Project/TastrUs/pageTastrUs';
import Esens from './Project/Esens/pageEsens';
import Neocity from './Project/Neocity/pageNeocity';

import PageLink from './link/pageLink';

import 'bulma/css/bulma.css';
import './App.css';

const App = () => {
  return (
    <div className='App'>
      <Router>
        {window.location.pathname !== '/link' ? <Header /> : null}

        <Routes>
          <Route
            path='/'
            element={<Home />}
          />
          <Route
            path='/project/SkeelApp'
            element={<SkeeLApp />}
          />
          <Route
            path='/project/TastrUs'
            element={<TastrUs />}
          />
          <Route
            path='/project/Esens'
            element={<Esens />}
          />
          <Route
            path='/project/Rutabago'
            element={<Rutabago />}
          />
          <Route
            path='/project/Neocity'
            element={<Neocity />}
          />
          <Route
            path='/link'
            element={<PageLink />}
          />
          <Route
            path='*'
            element={<NotFound />}
          />
        </Routes>

        {window.location.pathname !== '/link' ? (
          <div>
            <ScrollButton />
          </div>
        ) : null}
        <Footer />
        <Cookie />
      </Router>
    </div>
  );
};

export default App;
