import React from 'react';

import './pageNeocity.css';

const Neocity = () => {
  const linkNeocity = () => {
    window.open('https://neocity.fr/#', '_blank');
  };

  return (
    <section className='section is-medium paddingSection'>
      <div className='container content'>
        <h2 className='fontSizeTitle googleFontsConcertOne has-text-centered'>
          Neocity
        </h2>
        <p className='fontSizeSubtitle googleFontsMontserrat'>
          Neocity is a company specializing in the creation of mobile
          applications for communities, particularly town halls. Based in
          France, it offers tailor-made solutions to help communities improve
          their communication with citizens and facilitate the management of
          their territory.
        </p>
        <p className='fontSizeSubtitle googleFontsMontserrat'>
          My mission begins with the design of the V3 API. I work closely with
          front-end teams, project managers and stakeholders to understand their
          specific needs. I plan the API architecture, ensuring it is scalable,
          efficient, and able to meet a variety of use cases.
        </p>
        <h3 className='fontSizeSubtitle googleFontsMontserrat pt-3'>
          Back-end
        </h3>
        <p className='fontSizeSubtitle googleFontsMontserrat pb-3'>
          The creation of the Neocity back-end. Certain parameters had to be
          applied such as: scalability, robustness, low response time and easy
          maintenance API V3
        </p>
        <ul>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              <strong>Development :</strong> I am responsible for the
              development of the V3 API using Node.js and the NestJS framework,
              which allows efficient management of routes, controllers, and
              middleware. I ensure that the API responds quickly to requests and
              provides consistent responses.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              <strong>Security Integration :</strong> Security is a top
              priority. I implement security mechanisms such as authentication,
              authorization, and vulnerability protection to ensure that the V3
              API protects sensitive data.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              <strong>Testing and Validation :</strong> I write comprehensive
              unit and integration tests using Jest.js to ensure API
              reliability. I ensure that each component of the API is tested
              thoroughly.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              <strong>Documentation :</strong> Complete documentation is
              essential. I create detailed API V3 documentation to help
              front-end teams and third-party developers integrate it seamlessly
              into their applications.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              <strong>Performance Optimization :</strong> I continuously monitor
              and optimize the performance of the API, ensuring that it can
              effectively respond to peak loads and the evolving needs of our
              customers.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              <strong>Collaboration :</strong> I work closely with the front-end
              teams to ensure that the V3 API perfectly meets their needs. I
              take into account user feedback and potential improvements.
            </p>
          </li>
        </ul>
        <div className='myTechno pt-2'>
          <h4 className='fontSizeSubtitle googleFontsMontserrat'>
            Technologies used
          </h4>
          <div className='tags googleFontsPoppins'>
            <span className='tag is-dark fontSizeTag'>Node.js</span>
            <span className='tag is-dark fontSizeTag'>Nest.js</span>
            <span className='tag is-dark fontSizeTag'>MongoDb</span>
            <span className='tag is-dark fontSizeTag'>Docker</span>
            <span className='tag is-dark fontSizeTag'>AWS S3</span>
            <span className='tag is-dark fontSizeTag'>
              Firebase Notification
            </span>
            <span className='tag is-dark fontSizeTag'>Mandrill</span>
            <span className='tag is-dark fontSizeTag'>
              Unit tests (Jest.js)
            </span>
            <span className='tag is-dark fontSizeTag'>Typescript</span>
            <span className='tag is-dark fontSizeTag'>Loopback</span>
            <span className='tag is-dark fontSizeTag'>Postman</span>
            <span className='tag is-dark fontSizeTag'>Cron Node.js</span>
          </div>
        </div>
        <div className='most'>
          <h4 className='fontSizeSubtitle googleFontsMontserrat pb-2'>
            What the project brought me
          </h4>
          <blockquote className='fontSizeParagraphe googleFontsPoppins borderLeft'>
            Thanks to my involvement with Neocity, I experienced a significant
            boost in my knowledge. I also introduced important technologies that
            are widely used in the Node.js ecosystem today, such as TypeScript
            and unit testing, to the company.
          </blockquote>

          <blockquote className='fontSizeParagraphe googleFontsPoppins borderLeft'>
            I take great pride in having contributed to the creation and
            development of Neocity's back-end. It was a substantial challenge
            that I enthusiastically embraced. I hold a strong affection for
            Neocity, its team, and the cutting-edge technologies we employ."
          </blockquote>
        </div>

        <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
          You can currently download these apps from the app stores for several
          cities that were created with Neocity, including "Versailles,"
          "Geneva," and "Saint-Nazaire" (available on Google Play and the Apple
          App Store ).
        </p>

        <div className='buttons'>
          <button
            onClick={linkNeocity}
            className='button is-dark is-medium buttonRedirect'
          >
            <p className='fontSizeParagraphe googleFontsMontserrat'>Neocity</p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Neocity;
