import React from 'react';

import './pageTastrUs.css';

const TastrUs = () => {
  const linkTastr = () => {
    window.open('https://www.tastr.us/', '_blank');
  };

  return (
    <section className='section is-medium paddingSection'>
      <div className='container content'>
        <h2 className='fontSizeTitle googleFontsConcertOne has-text-centered'>
          Tastr.Us
        </h2>
        <p className='fontSizeSubtitle googleFontsMontserrat'>
          Tastr.us is a cultural recommendation mobile app. The user rates
          movies / series or music album from one to 10. And thanks to this
          rating, Tastr recommends works corresponding to your cultural
          preferences.
        </p>
        <p className='fontSizeSubtitle googleFontsMontserrat'>
          I was hired as a Back-end JavaScript developer. My mission this
          divided into two parts. The first part consists of the creation of the
          Full Tastr back-end. Then, secondly, optimization and creation of the
          Tastr mobile application.
        </p>
        <h3 className='fontSizeSubtitle googleFontsMontserrat pt-3'>
          Back-end
        </h3>
        <p className='fontSizeSubtitle googleFontsMontserrat pb-3'>
          The creation of the Tastr back-end. When creating the API, certain
          parameters had to be applied such as: scalability, robustness, low
          response time and easy maintenance API
        </p>
        <ul>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              Authentication: use of the “Firebase” service Authentication” for
              login / register management of internal users at Tastr.us.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              ElasticSearch: installation of ElasticSearch in order to optimize
              the answers of the search bars, internally to the application
              mobile.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              API routes: design of API routes in Node.js.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              Database: schematization and design of a database data in SQL.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              CI/CD: setting up a CI/CD between github, CircleCi and AWS.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              Integration tests: creation of integration tests with Mocha.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              Docker: deployment of a docker container on the Tastr API.
            </p>
          </li>
        </ul>
        <div className='myTechno pt-2'>
          <h4 className='fontSizeSubtitle googleFontsMontserrat'>
            Technologies used
          </h4>
          <div className='tags googleFontsPoppins'>
            <span className='tag is-dark fontSizeTag'>Node.js</span>
            <span className='tag is-dark fontSizeTag'>Express.js</span>
            <span className='tag is-dark fontSizeTag'>MySql</span>
            <span className='tag is-dark fontSizeTag'>AWS S3 & EC2</span>
            <span className='tag is-dark fontSizeTag'>Elasticsearch</span>
            <span className='tag is-dark fontSizeTag'>Firebase</span>
            <span className='tag is-dark fontSizeTag'>Unit tests</span>
            <span className='tag is-dark fontSizeTag'>
              CI / CD ( Circleci )
            </span>
          </div>
        </div>

        <h3 className='fontSizeSubtitle googleFontsMontserrat pt-3'>
          Front-end
        </h3>

        <p className='fontSizeSubtitle googleFontsMontserrat pb-3'>
          Optimization and creation of the Tastr mobile application then
          deployment on stores (google play, Apple Store)
        </p>
        <ul>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              Navigation: implementation of a navigation service with “React
              navigation”.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              Architecture: creation of a React-native architecture with “Expo”.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              API call: creation of an API call service.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              Request management: to optimize requests to the API depending on
              the action of the user, the integration of the module React-query
              has been done.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              Refactoring: addition of React-native hooks and TypeScript in the
              front Tastr app project.
            </p>
          </li>
          <li>
            <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
              Implementation of the style of the application.
            </p>
          </li>
        </ul>
        <div className='myTechno pt-2'>
          <h4 className='fontSizeSubtitle googleFontsMontserrat'>
            Technologies used
          </h4>
          <div className='tags googleFontsPoppins'>
            <span className='tag is-dark fontSizeTag'>React-native</span>
            <span className='tag is-dark fontSizeTag'>React-navigation</span>
            <span className='tag is-dark fontSizeTag'>React-query</span>
            <span className='tag is-dark fontSizeTag'>React Hook</span>
            <span className='tag is-dark fontSizeTag'>Axios</span>
            <span className='tag is-dark fontSizeTag'>AWS S3 & EC2</span>
          </div>
        </div>
        <div className='most'>
          <h4 className='fontSizeSubtitle googleFontsMontserrat pb-2'>
            What the project brought me
          </h4>
          <blockquote className='fontSizeParagraphe googleFontsPoppins borderLeft'>
            With Tastr, I discovered the world of incubator start-ups. It was a
            very enriching experience both on the human and technical side
            during the course of this mission.
          </blockquote>

          <blockquote className='fontSizeParagraphe googleFontsPoppins borderLeft'>
            I am proud to have participated in the creation/reflection of the
            Back-end and Front-end of the Tastr application.
          </blockquote>
        </div>

        <p className='fontSizeParagraphe googleFontsPoppins pb-1'>
          Currently available for download on the stores (Google Play & Apple
          Store)
        </p>

        <div className='buttons'>
          <button
            onClick={linkTastr}
            className='button is-dark is-medium buttonRedirect'
          >
            <p className='fontSizeParagraphe googleFontsMontserrat'>Tastr.us</p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default TastrUs;
