import React from 'react';
import 'bulma-timeline/dist/css/bulma-timeline.min.css';
import dataSchool from '../../Services/data/dataUser.json';
import {IFormation} from '../../types/interfaces';

const Fetchdataschool = () => {
  return (
    <>
      {dataSchool.map((array) => {
        let dataArray = array.formation.map((element: IFormation) => {
          return (
            <div
              className='timeline-item'
              key={element.Key}
            >
              <div className='timeline-marker is-dark'></div>
              <div className='timeline-content'>
                <h3 className='heading has-text-weight-bold googleFontsPoppins fontSizeParagraphe'>
                  {element.nameOfDegree}
                </h3>
                <h4 className='is-size-7 has-text-weight-light googleFontsMontserrat'>
                  {element.Date}
                </h4>
                <h3 className='googleFontsMontserrat fontSizeParagraphe'>
                  {element.CourseTtile}
                </h3>
              </div>
            </div>
          );
        });
        return dataArray;
      })}
    </>
  );
};

export default Fetchdataschool;
