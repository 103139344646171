import React from 'react';

import './Home.css';

import AboutMe from '../../Components/AboutMe/aboutMe';
import Timeline from '../../Components/Timeline/timeline';
import Technology from '../../Components/Technology/technology';
import Project from '../../Components/Project/project';
import Contact from '../../Components/Contact/contact';

const Home = () => {
  return (
    <div>
      <section id="aboutMe" className="section is-medium paddingSection">
        <AboutMe />
      </section>
      <section id="experience" className="section is-medium paddingSection">
        <Timeline />
      </section>
      <section id="project" className="section is-medium paddingSection">
        <Technology />
      </section>
      <section className="section project is-medium paddingSection">
        <Project />
      </section>
      <section id="contact" className="section is-medium paddingSection">
        <Contact />
      </section>
    </div>
  );
};

export default Home;
