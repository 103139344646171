import React from 'react';

import data from '../../Services/data/dataUser.json';

const FetchDataUser = () => {
  return (
    <div
      className='column'
      key={data[0].user.key}
    >
      <h1 className='is-size-3 has-text-centered pt-5 is-dark has-text-weight-light googleFontsConcertOne'>
        {data[0].user.name}
      </h1>
      <h2 className='is-size-4 has-text-centered has-text-weight-light googleFontsMontserrat'>
        {data[0].user.title}
      </h2>
      <h2 className='is-size-4 has-text-centered has-text-weight-light googleFontsMontserrat'>
        {data[0].user.subtitle}
      </h2>
      <p className='is-size-6 has-text-centered pt-3 has-text-weight-light textBio googleFontsPoppins fontSizeParagraphe'>
        {data[0].user.text}
      </p>
      <p className='is-size-6 has-text-centered has-text-weight-light textBio googleFontsPoppins fontSizeParagraphe'>
        {data[0].user.location}
      </p>
    </div>
  );
};

export default FetchDataUser;
